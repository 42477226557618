import React, { Component } from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { exposeMetrics } from 'react-metrics';
import { PropTypes as MetricsPropTypes } from 'react-metrics';
import { Form } from 'react-final-form';
import format from 'string-template';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import { PrimaryButton } from 'components/Shared/Button/Button';
import FormField from 'components/Shared/Form/FormField/FormField';
import FormRow from 'components/Shared/Form/FormRow/FormRow';
import Img from 'gatsby-image';
import ZipApi from 'components/Shared/ZipApi';
import Validators, {
  composeValidators,
  prepareErrors,
} from 'components/Shared/Form/validators';
import ModalWindow from 'components/ModalWindow/ModalWindow';
import ZipContext from 'components/Shared/ZipContext';
import { getZip, setZip } from 'components/ZipGateContainer/ZipGateContainer';
import { withZipGateData } from 'components/ZipGateContainer/ZipGateDataHOC';
import './HeroWithZipForm.scss';

class ZipForm extends Component {
  constructor(props) {
    super(props);
    this.decorators = [];
    this.validators = [];
    this.props.data.zipForm.formFields.forEach(field => {
      const validators = [];
      const messages = prepareErrors(field.fieldErrors);
      field.validators.forEach(validator => {
        if (validator in Validators) {
          validators.push(Validators[validator](messages[validator]));
        }
      });
      if (field.required) {
        validators.push(Validators.required(messages));
      }
      this.validators[field.name] = composeValidators(...validators);
    });
  }

  submit = async values => {
    const errorMessages = {
      invalidZip: 'Invalid zip code',
      genericError: 'Unable to validate zip',
      ...this.props.data.zipForm.formErrors.reduce((obj, err) => {
        obj[err.key] = format(err.value, { zip: values.zip });
        return obj;
      }, {}),
    };
    try {
      const zipInfo = await ZipApi.getZipInfo(values.zip);
      this.props.callback({
        zip: parseInt(values.zip),
        club: parseInt(zipInfo.services.geo.postal.clubs.club[0].code),
      });
    } catch (err) {
      window.Sentry.captureException(err);
      if (err.status === 500) {
        return {
          zip: errorMessages.invalidZip,
        };
      }
      return {
        zip: errorMessages.genericError,
      };
    }
  };

  render() {
    const { data } = this.props;
    return (
      <Form
        onSubmit={this.submit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            {data.zipForm.formFields.map(field => (
              <FormRow key={field.name} className="row">
                <div className="col-xs-12 col-lg-6">
                  <FormField
                    data={field}
                    validate={this.validators[field.name]}
                  />
                </div>
                <div className="col-xs-12 col-lg-4 hero-with-zip__form-col">
                  <PrimaryButton
                    className="hero-with-zip__cta-button"
                    disabled={submitting ? 'disabled' : ''}
                    type="submit"
                  >
                    {data.zipForm.submitTitle}
                  </PrimaryButton>
                </div>
              </FormRow>
            ))}
          </form>
        )}
      />
    );
  }
}

class HeroWithZipForm extends Component {
  constructor() {
    super();
    this.state = {
      mounted: false,
      showZipGate: false,
      shouldRedirect: false,
    };
  }

  async componentDidMount() {
    this.setState({ mounted: true });
  }

  handleZip = ({ zip, club }) => {
    const { data } = this.props;
    setZip({ zip, club });
    this.props.zipContext.setZip({ zip, club });
    if (data.allowedZips.zips.indexOf(zip) !== -1) {
      if (data.mwg) {
        this.props.metrics.track('in_market', {
          zip_code: zip,
          label: 'sorry_form',
          nonInteraction: 1,
        });
        this.performRedirectToReturnURL();
      } else {
        this.setState({ shouldRedirect: true });
      }
    } else {
      if (!data.mwg) {
        this.props.metrics.track('in_market', {
          zip_code: zip,
          label: 'sorry_form',
          nonInteraction: 1,
        });
        this.performRedirectToReturnURL();
      } else {
        this.setState({ shouldRedirect: true });
      }
    }
  };

  performRedirect = () => {
    const { data } = this.props;
    const { zip, club } = getZip();
    const url = `${data.redirectURL}?${queryString.stringify({
      zip,
      club,
    })}`;
    window.location.replace(url);
  };

  performRedirectToReturnURL = () => {
    const { location } = this.props;
    if (location.state && location.state.returnURL) {
      return navigate(location.state.returnURL);
    }
    const parsed = queryString.parse(location.search);
    const returnURL = parsed.returnURL;
    if (returnURL) {
      return window.location.replace(decodeURIComponent(returnURL));
    }
    return navigate('/');
  };

  render() {
    const { data, heroData } = this.props;
    if (!this.state.mounted) {
      return null;
    }

    return (
      <div className="hero-with-zip">
        {this.state.shouldRedirect && (
          <ModalWindow
            open
            timeout={5000}
            onClose={this.performRedirect}
            render={() => {
              return (
                <div>
                  <div className="row">
                    <div className="aaa-zip-form__logo-container col-12">
                      <img
                        alt="aaa-logo"
                        className="aaa-zip-form__logo"
                        src={data.logo.publicURL}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="aaa-zip-form__zip-prompt col-12">
                      {data.redirectMessage}
                    </div>
                  </div>
                </div>
              );
            }}
          >
            {() => {
              return null;
            }}
          </ModalWindow>
        )}
        <Img
          className="hero-with-zip__image"
          fluid={heroData.heroImage.childImageSharp.fluid}
        />
        <div className="hero-with-zip__overlay">
          <div className="hero-with-zip__content row no-gutters">
            <div className="col-sm-12 col-md-10 offset-md-1 col-lg-6 offset-lg-2">
              <div className="hero-with-zip__cta-card">
                <h1 className="hero-with-zip__title">{heroData.title}</h1>
                <div className="hero-with-zip__subtitle-container">
                  <h3 className="hero-with-zip__subtitle">{heroData.subtitle}</h3>
                  <ZipForm data={data} callback={this.handleZip} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SeparatorWithGradient />
      </div>
    );
  }
}

class CombinedContext extends Component {
  constructor() {
    super();
  }

  static contextTypes = {
    metrics: MetricsPropTypes.metrics,
  };

  render() {
    return (
      <ZipContext.Consumer>
        {context1 => <HeroWithZipForm {...this.props} zipContext={context1} />}
      </ZipContext.Consumer>
    );
  }
}
export default withZipGateData(exposeMetrics(CombinedContext));

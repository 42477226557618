import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import HeroWithZipForm from 'components/HeroWithZipForm/HeroWithZipForm';

const SorryPage = ({ data, location }) => {
  return (
    <Layout location={location} type="error">
      <SEO meta={data.sorryYaml.metaTags} title={data.sorryYaml.title} />
      <HeroWithZipForm heroData={data.sorryYaml.hero} location={location} />
    </Layout>
  );
};

export default SorryPage;

export const pageQuery = graphql`
  query SorryPageQuery {
    sorryYaml {
      id
      title
      metaTags {
        name
        content
      }
      hero {
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        subtitle
      }
    }
  }
`;
